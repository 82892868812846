import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { ChangeEventHandler, useState, useMemo } from 'react'
import * as Yup from 'yup'
// import Button from '../Button/Button'
import { Button, Input, Typography } from '@material-tailwind/react'
import Selector from '../SelectInput/Select'
import AvatarLogo from '../../icons/AccountLogo.svg'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { storage } from '../../firebaseApp'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../store/store'
import Toast from '../Toast/Toast'
import firebase from 'firebase/compat/app'
import axios from 'axios'

const AdInfringementWithImage = () => {
  const [status, setStatus] = useState<string>('identified')
  const [imageURL, setImageURL] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const { userData } = useAppSelector((store) => store.user)
  const [errorMessage, setErrorMessage] = useState('')
  const [keywordId, setKeywordId] = useState('')
  const { sortedRegions } = useAppSelector((store) => store.plan)
  const [country, setCountry] = useState('')

  const countries = useMemo(() => {
    return sortedRegions
      .map((sortedRegion: any) => {
        if (sortedRegion.countries.length > 0) {
          return sortedRegion.countries.map((country: any) => {
            return {
              value: country.stripe_metadata_countryCode,
              label: country.name,
            }
          })
        } else {
          return []
        }
      })
      .flat()
      .sort((a: { label: string }, b: { label: string }) =>
        a.label.localeCompare(b.label),
      )
  }, [sortedRegions])

  const handleSubmit = async (values: any, { resetForm }: any) => {
    const { ...rest } = values
    const token = await firebase?.auth()?.currentUser?.getIdToken()
    const selectedCountry = countries.find(
      (c: { value: string }) => c.value === country,
    )
    const countryName = selectedCountry ? selectedCountry.label : ''
    await axios
      .post(
        `${process.env.REACT_APP_FIREBASE_API}/addInfringementDetails`,
        {
          data: {
            operation: 'ad-infringement-with-image',
            keywordId: keywordId,
            imageURL: imageURL,
            status: status,
            countries: countryName,
            ad_domain_displayed: rest.adDomain,
            ad_subtitle: rest.adSubtitle,
            ad_title: rest.adTitle,
            advertiser_name: rest.advertiserName,
            adSource: rest.adSource,
            adTrackingUrl: rest.adTrackingUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Expose-Headers': 'Content-Disposition',
          },
        },
      )
      .then((res) => {
        console.log(res.data.message)
        setImageURL('')
        setKeywordId('')
        Toast({
          // title: 'Document created successfully!',
          title: res.data.message,
          variant: 'success',
          options: { autoClose: 5000 },
        })
      })
      .catch((error: any) => {
        setImageURL('')
        setKeywordId('')
        console.log(error)
        Toast({
          title: 'Creation Failed!',
          variant: 'error',
          options: { autoClose: 5000 },
        })
      })
    resetForm()
  }

  const validationSchema = Yup.object({
    //country: Yup.string().required('At least one country is required'),
    adDomain: Yup.string().required('Ad Domain is required'),
    adSource: Yup.string().required('Ad Source is required'),
    adTrackingUrl: Yup.string().url('Must be a valid URL'),
    adTitle: Yup.string().required('Ad Title is required'),
    adSubtitle: Yup.string().required('Ad Subtitle is required'),
    advertiserName: Yup.string().required('Advertiser Domain Name is required'),
  })
  console.log('Validation schema', validationSchema)
  const allStatuses = [
    { value: 'reported', label: 'Reported' },
    { value: 'resolved', label: 'Resolved' },
    { value: 'identified', label: 'Identified' },
    { value: 'not an incident', label: 'Not an incident' },
  ]
  const handleStatusChange = async (
    value: string | undefined,
  ): Promise<void> => {
    const st = value || ''
    setStatus(st as string)
  }

  const handlePictureChange = async (event: {
    target: { files: File[] }
  }): Promise<void> => {
    setIsLoading(true)
    try {
      const file = event.target.files[0]
      const name = `${file.name}_${Date.now()}`
      const storageRef = ref(storage, `${keywordId}/${name}`)
      const metadata = {
        contentType: 'image/jpeg',
      }
      await uploadBytes(storageRef, file, metadata)
      const url = await getDownloadURL(storageRef)
      setImageURL(url)
      // console.log(url)
    } catch (error) {
      console.error('Error uploading file:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleUploadButtonClick = (): void => {
    if (keywordId === '') {
      setErrorMessage('Please add a keyword ID first')
      return
    } else {
      setErrorMessage('')
    }
    document.getElementById('contained-button-file')?.click()
  }

  const handleCountryChange = async (
    value: string | undefined,
  ): Promise<void> => {
    const st = value || ''
    setCountry(st as string)
  }

  return (
    <div className="flex flex-row flex-wrap items-center gap-6">
      <Formik
        initialValues={{
          //country: '',
          adDomain: '',
          adSource: '',
          adTrackingUrl: '',
          adTitle: '',
          adSubtitle: '',
          advertiserName: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="w-96 space-y-4">
            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Status
              </label>
              <Selector
                selectClassName="border border-blue-gray-100 rounded-lg uppercase font-semibold w-full"
                className="w-full"
                options={allStatuses}
                selectedValue={status}
                onChange={handleStatusChange}
                children={undefined}
              />
            </div>
            <div className="mb-1 flex flex-col my-3">
              <Typography
                placeholder={undefined}
                variant="h6"
                className="mb-1 text-sm font-medium text-wellBlueGray-900"
              >
                Country
              </Typography>
              <Selector
                selectClassName="border border-blue-gray-100 rounded-lg uppercase font-semibold w-full"
                className="w-full"
                options={countries}
                selectedValue={country}
                onChange={handleCountryChange}
                children={undefined}
                placeholder={'e.g. United States of America'}
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Keyword Id
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="1234567890"
                name="keywordId"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                value={keywordId}
                onChange={(e: {
                  target: { value: React.SetStateAction<string> }
                }) => setKeywordId(e.target.value)}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="keywordId"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>

            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Advertiser Domain Name
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="e.g. zoominfo.com"
                name="advertiserName"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="advertiserName"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>

            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Ad Title
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="e.g. Best Zoominfo Alternative"
                name="adTitle"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="adTitle"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Ad Subtitle
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="e.g. Zoominfo Competitor - Accelerate sales cycle ..."
                name="adSubtitle"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="adSubtitle"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>

            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Ad Source
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="e.g. Zoominfo"
                name="adSource"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="adSource"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>

            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Ad Domain Displayed
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="e.g., https://www.example.com"
                name="adDomain"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="adDomain"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>

            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Ad Tracking URL
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="e.g., https://www.example.com"
                name="adTrackingUrl"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="adTrackingUrl"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>

            <div>
              {keywordId === '' && errorMessage ? (
                <p className="text-red-700 text-xs font-normal text-start">
                  {errorMessage}
                </p>
              ) : (
                ''
              )}
              <Button
                type="submit"
                disabled={isSubmitting}
                className="!bg-lstnGreen-500 w-full mt-2 py-2 text-white rounded-lg flex justify-center"
                placeholder={undefined}
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="flex-col items-center !w-fit md:pl-6 justify-center relative">
        <Typography
          className="text-base font-medium text-lstnBlueGray-900 mb-2"
          placeholder={undefined}
        >
          Upload Image
        </Typography>
        <div className="mb-3 flex justify-center">
          {imageURL ? (
            <img
              src={imageURL?.toString() ?? undefined}
              width="74px"
              height="74px"
              className="rounded-full"
            />
          ) : (
            <img src={AvatarLogo} width="74px" height="74px" />
          )}
        </div>
        <div className="flex justify-center">
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            hidden
            onChange={
              handlePictureChange as unknown as ChangeEventHandler<HTMLInputElement>
            }
          />

          <label htmlFor="contained-button-file" className="block">
            <Button
              placeholder={undefined}
              variant="outlined"
              size="sm"
              onClick={handleUploadButtonClick}
              loading={isLoading ? isLoading : !userData}
            >
              {t('uploadButton.uploadAvatar')}
            </Button>
          </label>
        </div>
      </div>
    </div>
  )
}

export default AdInfringementWithImage
