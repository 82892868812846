import React, { useMemo } from 'react'
import firebase from 'firebase/compat/app'
import Header from './Header'
import Sidebar from './Sidebar'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../store/store'
import { KeywordDialogRef } from '../pages/Keywords'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-tailwind/react'
// import { useAppSelector } from '../store/store'
type LayoutProps = {
  children: React.ReactNode
  user: firebase.User
  isEnterprise?: boolean
  childRef: KeywordDialogRef | any
}
const Layout: React.FC<LayoutProps> = ({
  children,
  user,
  isEnterprise,
  childRef,
}) => {
  const location = useLocation()
  const { loading } = useAppSelector((store) => store.project)
  const { keywords } = useAppSelector((store) => store.keyword)
  const { t } = useTranslation()
  const checkPath = useMemo(() => {
    const currentPath = location && location.pathname
    if (
      currentPath === '/dashboard' ||
      currentPath === '/content-marketing' ||
      currentPath === '/directories-listing' ||
      currentPath === '/integrations' ||
      currentPath === '/project-settings' ||
      (currentPath.includes('/keyword/') &&
        !currentPath.match(/^\/keyword\/[^/]+\/[^/]+$/)) ||
      currentPath === '/keywords'
      //    &&
      // loading === 'succeeded'
    ) {
      return location.pathname
    } else {
      return
    }
  }, [loading, location])

  const isKeywordRoute = useMemo(() => {
    return /^\/keyword\/[^/]+\/[^/]+$/.test(location.pathname)
  }, [location.pathname])

  const isEditKeywordRoute = useMemo(() => {
    return (
      location.pathname.includes('/keyword/') &&
      location.pathname.includes('edit')
    )
  }, [location.pathname])
  console.log('USER: ', user)

  return (
    <>
      {user &&
        user.uid &&
        keywords.length === 0 &&
        location.pathname !== '/create-account' && (
          <div className="w-full h-9 bg-lstnGreen-500 flex justify-center text-center items-center">
            <Typography
              className="text-base font-medium text-white flex items-center gap-1"
              placeholder={undefined}
            >
              {t('keyword.discountOffer')}
            </Typography>
          </div>
        )}
      <div className="flex">
        {user && user.uid && (checkPath || isEditKeywordRoute) && (
          <div className="h-screen hidden lg:flex w-[22rem] sticky top-0">
            <Sidebar childRef={childRef} />
          </div>
        )}
        <div
          className={`app-container w-full ${
            isKeywordRoute && !isEditKeywordRoute ? 'bg-[#F9FAFB]' : ''
          }`}
        >
          {user && user.uid && location.pathname !== '/create-account' ? (
            <Header
              isEnterprise={isEnterprise}
              userId={user.uid}
              children={undefined}
              childRef={childRef}
            />
          ) : (
            ''
          )}
          {children}
        </div>
      </div>
    </>
  )
}

export default Layout
